/* You can add global styles to this file, and also import other style files */
@import "node_modules/bootstrap/scss/bootstrap";
html, body { height: 100%;}
.team_list_row_class{
    cursor: pointer !important;
}
.m_b_20
{
    margin-bottom: 20px;
}
.f_s_25
{
    font-size: 25px !important;
}
.f_s_20
{
    font-size: 20px !important;
}
.m_b_15
{
    margin-bottom: 15px !important;
}
.login_margin
{
    margin-top: 30px;
    margin-bottom: 15px;
   
}
.f_s_14
{
    font-size: 14px !important;
}

.modal-dialog.modal-md {
    max-width: 900px;
    margin: 0 auto;
}
.modal-dialog.modal-lg {
    max-width: 1300px;
    margin: 0 auto;
}
.modal-dialog.modal-elg {
    max-width: 1700px;
    margin: 0 auto;
}
.modal-content {
    background-color: transparent;
    border: none;
}

.main-content {
    margin: 0 auto;
}

.k-checkbox {
    border-color: rgb(0 0 0 / 37%) !important;
    color: transparent;
    background-color: #ffffff;
    height: 16px;
    width: 16px;
}

.k-grid {
    font-size: 12px !important;
}

.k-cell-inner > .k-link {
    font-size: 13px !important;
}

.k-grid th, .k-grid td {
    padding: 5px 10px !important;
}

.k-filtercell .k-filtercell-operator {
    display: none !important;
}

.k-datepicker .k-picker-wrap, .k-timepicker .k-picker-wrap, .k-datetimepicker .k-picker-wrap, .k-dateinput .k-dateinput-wrap {
    background-color: #f5f5f5 !important;
    border-radius: 0 !important;
}

.training_type_header .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
        border-color: transparent !important;
        color: #424242;
        background-color: transparent !important;
        background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.02));
}

.navbar-toggler {
	padding: 4px 8px;
	font-size: 14px;
	background-color: #fff;
	border: 1px solid #fff;
	border-radius: 2px;
	transition: box-shadow 0.15s ease-in-out;
}
.navbar-toggler:focus { box-shadow:none; border-color:#0172C5;}


@media (max-width: 575.98px){
    .contentpart { margin-top: 280px !important;}
    .datacall{
        padding-right: 13px;
        margin-bottom: 15px;
       }
}

@media (min-width: 576px) and (max-width: 767.98px) { 
    
    .modal-dialog {  max-width:100%; }

}

@media (min-width: 768px) and (max-width: 991.98px) { 
  
    .modal-dialog { max-width:100%;  margin: 1.75rem auto; }
    .logo-right { padding-right:10px;}
    .logo-right img { width: 78% !important; }
 }



 $i-pad-screen: "screen and (min-width: 768px) and (max-width: 1024px)";
 $fix-screen:"screen and (min-width: 320px) and (max-width: 425px)";
 @media #{$i-pad-screen} {
     .contet-hhd{
       margin-top: 50px !important;
     }
 }
 @media #{$fix-screen}{
     .contet-hhd{
         margin-top: 20px !important;
     }
 }







